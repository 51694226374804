// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bulma"
import "stylesheets/application.scss"
import "@fortawesome/fontawesome-free/css/all"

require.context('../images', true)
Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

// disables default "file upload" button for actiontext rich text library.
// actiontext is used in: `Game` form, `additional_instrucitons` field
window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
  alert("File attachment not supported!")
});

document.addEventListener("turbolinks:load", function(event) {
  gtag('config', 'G-13TVE1NFLK', {'page_location': event.data.url});
});
